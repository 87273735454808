// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-whoami-js": () => import("./../src/pages/whoami.js" /* webpackChunkName: "component---src-pages-whoami-js" */),
  "component---src-pages-work-diabetes-treatment-tools-js": () => import("./../src/pages/work/diabetes-treatment-tools.js" /* webpackChunkName: "component---src-pages-work-diabetes-treatment-tools-js" */),
  "component---src-pages-work-inspired-ehrs-js": () => import("./../src/pages/work/inspired-ehrs.js" /* webpackChunkName: "component---src-pages-work-inspired-ehrs-js" */),
  "component---src-pages-work-partners-irb-js": () => import("./../src/pages/work/partners-irb.js" /* webpackChunkName: "component---src-pages-work-partners-irb-js" */)
}

